// Generated by Framer (c93245f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Vimeo from "https://framerusercontent.com/modules/0sWquksFr1YDkaIgrl9Z/VgWe6mCMJOseqaLiMnaC/Vimeo.js";
import * as localizedValues from "./CUhf4g4SD-0.js";
const VimeoFonts = getFonts(Vimeo);

const enabledGestures = {YWezNFJz_: {hover: true}};

const cycleOrder = ["YWezNFJz_"];

const serializationHash = "framer-QSnVS"

const variantClassNames = {YWezNFJz_: "framer-v-h4dxew"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {EcHMMQmqc: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, uRL, width, ...props}) => { return {...props, i50dzaCjQ: uRL ?? props.i50dzaCjQ ?? "https://vimeo.com/915096488"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;uRL?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, i50dzaCjQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YWezNFJz_", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-h4dxew", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YWezNFJz_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"YWezNFJz_-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-rgkb98-container"} data-framer-name={"Video 1 Vimeo"} layoutDependency={layoutDependency} layoutId={"arNCArBV8-container"} name={"Video 1 Vimeo"}><Vimeo autoplay={false} backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={10} bottomLeftRadius={10} bottomRightRadius={10} controls={false} height={"100%"} id={"arNCArBV8"} isMixedBorderRadius={false} layoutId={"arNCArBV8"} loop mute name={"Video 1 Vimeo"} style={{height: "100%", width: "100%"}} topLeftRadius={10} topRightRadius={10} video={i50dzaCjQ} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QSnVS.framer-1rdysd8, .framer-QSnVS .framer-1rdysd8 { display: block; }", ".framer-QSnVS.framer-h4dxew { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 431px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 767px; }", ".framer-QSnVS .framer-rgkb98-container { aspect-ratio: 1.7777777777777777 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 431px); position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QSnVS.framer-h4dxew { gap: 0px; } .framer-QSnVS.framer-h4dxew > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QSnVS.framer-h4dxew > :first-child { margin-left: 0px; } .framer-QSnVS.framer-h4dxew > :last-child { margin-right: 0px; } }", ".framer-QSnVS.framer-v-h4dxew.hover.framer-h4dxew { aspect-ratio: 1.7795823665893271 / 1; height: var(--framer-aspect-ratio-supported, 431px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 431
 * @framerIntrinsicWidth 767
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"KR0Q5jV5e":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"i50dzaCjQ":"uRL"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerCUhf4g4SD: React.ComponentType<Props> = withCSS(Component, css, "framer-QSnVS") as typeof Component;
export default FramerCUhf4g4SD;

FramerCUhf4g4SD.displayName = "Video-Hover";

FramerCUhf4g4SD.defaultProps = {height: 431, width: 767};

addPropertyControls(FramerCUhf4g4SD, {i50dzaCjQ: {defaultValue: "https://vimeo.com/915096488", title: "URL", type: ControlType.String}} as any)

addFonts(FramerCUhf4g4SD, [{explicitInter: true, fonts: []}, ...VimeoFonts], {supportsExplicitInterCodegen: true})